<template>
  <div class="Checkout__Step PaymentOptions" ref="CheckoutStep">
    <UnavailableItemsModal  v-if="ShowUnavailableItems" />
    <el-form ref="paymentOptions" class="Checkout__Form--Custom Form--Custom">
      <div class="Checkout__Section">
        <ContactInfoPreview :edit="true" :show-products="false" :data="Checkout" :fields="['contact_info', 'shipping_info']"/>
      </div>
      <div class="Checkout__Section hide--md">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="el-form-outer">
              <Promotions />
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="Checkout__Section">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="el-form-outer">
              <h2 class="Checkout__Section__Title">Pagamento</h2>
              <div v-loading="CheckoutStatus.PaymentOptions.get && CheckoutStatus.PaymentOptions.get === 'IDLE'">
                <el-alert
                  v-if="!PaymentOptions && CheckoutStatus.PaymentOptions.get === 'SUCCESS'"
                  title="NÃO ENCONTRAMOS NENHUM METODO DE PAGAMENTO"
                  type="warning"
                  description="NÃO ENCONTRAMOS NENHUM METODO DE PAGAMENTO"
                  show-icon
                ></el-alert>
                <el-alert
                  v-if="!PaymentOptions && CheckoutStatus.PaymentOptions.get === 'ERROR'"
                  title="ERRO AO BUSCAR metodos de PAGAMENTO"
                  type="error"
                  description="ERRO AO BUSCAR metodos de PAGAMENTO"
                  show-icon
                ></el-alert>
                <el-collapse
                  v-model="chosenMethod"
                  accordion
                  @change="ChoosePaymentProvider(chosenMethod)"
                >
                  <el-collapse-item
                    v-for="(method, index) in PaymentOptions"
                    :key="index"
                    :name="JSON.stringify(method)"
                    class="PaymentOption"
                    :class="{'el-collapse-item--selected':method.id === Payment.paymentId}"
                  >
                    <template slot="title">
                      <icon v-if="method.type === 'credit_card'" :src="require('../../../../assets/icons/main/credit-card.svg')" width="20" :aria-label="method.name" class="header-icon el-collapse-item__header__icon"></icon>
                      <icon v-if="method.type === 'two_cards_payment'" :src="require('../../../../assets/icons/main/two-credit-cards.svg')" width="20" :aria-label="method.name" class="header-icon el-collapse-item__header__icon"></icon>
                      <icon v-if="method.type === 'boleto'" :src="require('../../../../assets/icons/main/barcode.svg')" width="20" :aria-label="method.name" class="header-icon el-collapse-item__header__icon"></icon>
                      <icon v-if="method.type === 'pix'" :src="require('../../../../assets/icons/main/pix_icon.svg')" width="20" :aria-label="method.name" class="header-icon el-collapse-item__header__icon"></icon>
                      <h2 class="PaymentOption__Title">{{method.name}}</h2>
                      <el-tag
                        type="primary"
                        effect="dark"
                        size="mini"
                        class="PaymentOption__Tag--Discount"
                        v-if="method.installments[0].discount_rate > 0 && ShouldShowDiscountTag"
                      >{{method.installments[0].discount_rate}}% de Desconto</el-tag>
                      <el-tag
                        type="primary"
                        effect="dark"
                        size="mini"
                        class="PaymentOption__Tag--Selected"
                        v-if="method.id === Payment.paymentId"
                      >Selecionado</el-tag>
                    </template>
                      <div v-if="method.type === 'two_cards_payment'">
                        <MultipleCreditCardsPayment :ref="`PaymentMethod--${method.type}`"  :method="method" v-if="method.id === Payment.paymentId"/>
                      </div>
                      <div v-else>
                        <PaymentForm :ref="`PaymentMethod--${method.type}`" :method="method" v-if="method.id === Payment.paymentId"/>
                      </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </el-col>
          <payment-error />
          <el-col :xs="{span:24, offset: 0}" :md="{span:18, offset: 3}" :lg="{span:12, offset: 6}">
            <el-button
              id="continueButton"
              :disabled="!PaymentOptions"
              type="primary"
              class="el-button--block Checkout__Button--primary"
              @click="ContinuePayment(Checkout)"
              :loading="loading"
            >
              Finalizar
              <i class="el-icon-arrow-right"></i>
            </el-button>
            <el-button type="text"  class="el-button--block" @click="goToPrev(Checkout)">
              <i class="el-icon-arrow-left"></i> Voltar
            </el-button>
            <br>
            <h6 style="text-align: center; color: #606266;">Ao realizar a compra, você concorda com: <el-link v-if="TermsOfServiceURL" :href="TermsOfServiceURL" target="_blank" style=" text-decoration: underline; ">Nossos Termos de Serviço</el-link> <el-link  v-if="PrivacyPolicyURL" :href="PrivacyPolicyURL" target="_blank" style=" text-decoration: underline; ">Nossa Política de Privacidade</el-link> <el-link  v-if="WarrantyPolicyURL" :href="WarrantyPolicyURL" target="_blank" style=" text-decoration: underline; ">Nossa Política de Garantia</el-link> <el-link v-if="HasRewardProgram && RewardProgramURL" :href="RewardProgramURL" target="_blank" style=" text-decoration: underline; ">Termos do programa de fidelidade</el-link> </h6>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>

<script>
import ContactInfoPreview from '@/components/contactInfo/ContactInfoPreview.vue'
import PaymentForm from '@/components/_paymentOptions/PaymentForm.vue'
import MultipleCreditCardsPayment from '@/components/_paymentOptions/MultipleCreditCardsPayment.vue'
import UnavailableItemsModal from '@/components/_productModal/UnavailableItemsModal.vue'
import Promotions from '@/components/_promotions/Promotions.vue'
import eventBus from '@/helpers/eventBus'
import { mapFilters } from '@/helpers/filters'
import Icon from 'vue-inline-svg'
import { mapActions, mapGetters } from 'vuex'
import PaymentError from './components/PaymentError.vue'

export default {
  name: 'PaymentOptions',
  components: {
    Icon,
    Promotions,
    PaymentForm,
    MultipleCreditCardsPayment,
    ContactInfoPreview,
    UnavailableItemsModal,
    PaymentError
  },
  data () {
    return {
      BillingInfoFormOpen: false,
      chosenMethod: '',
      loading: false,
      creditCardErrorHandler: null
    }
  },
  created () {
    this.GetPaymentOptions(this.Checkout.id)
    this.clearPaymentState()
  },
  mounted () {
    var $this = this
    eventBus.$on('productsUpdated', function () {
      $this.GetPaymentOptions($this.Checkout.id)
      $this.clearPaymentState()
    })

    eventBus.$on('creditCardFormFulfilled', function (cardHash) {
      // $this.loading = true
    })

    eventBus.$on('creditCardHashCreated', function (cardHash) {
      // $this.loading = false
    })
  },
  computed: {
    ...mapGetters([
      'AppConfig',
      'CheckoutStatus',
      'Checkout',
      'Payment',
      'PaymentOptions',
      'ShowUnavailableItems',
      'ActivePaymentMethod',
      'CheckoutConfig',
      'IsNewBillingInfo'
    ]),
    HasRewardProgram () {
      return this.AppConfig.contact_information.has_reward_program
    },
    ShouldShowDiscountTag () {
      return this.AppConfig.payment_informations.show_discount_tag
    },
    RewardProgramURL () {
      if (this.AppConfig.contact_information.has_reward_program) {
        return `${this.CheckoutConfig.shopify_api.domain}pages/termos-programa-de-fidelidade`
      }
      return null
    },
    TermsOfServiceURL () {
      if (this.AppConfig.components.footer && this.AppConfig.components.footer.terms_of_service) {
        if (this.AppConfig.components.footer.terms_of_service.enabled && this.AppConfig.components.footer.terms_of_service.url) {
          return `${this.AppConfig.components.footer.terms_of_service.url}`
        } else {
          return null
        }
      }
      return `${this.CheckoutConfig.shopify_api.domain}/policies/terms-of-service`
    },
    PrivacyPolicyURL () {
      if (this.AppConfig.components.footer && this.AppConfig.components.footer.privacy_policy) {
        if (this.AppConfig.components.footer.privacy_policy.enabled && this.AppConfig.components.footer.privacy_policy.url) {
          return `${this.AppConfig.components.footer.privacy_policy.url}`
        } else {
          return null
        }
      }
      return `${this.CheckoutConfig.shopify_api.domain}/policies/privacy-policy`
    },
    WarrantyPolicyURL () {
      if (this.AppConfig.components.footer && this.AppConfig.components.footer.warranty_policy) {
        return `${this.AppConfig.components.footer.warranty_policy.url}`
      }
      return null
    }
  },
  methods: {
    ...mapActions([
      'validateCheckout',
      'setAnalyticsError',
      'GetPaymentOptions',
      'setCheckoutStatus',
      'SetPaymentState',
      'SetActivePaymentMethod',
      'UpdatePaymentState',
      'UpdateCheckoutState',
      'goToPrevStep',
      'goToStep',
      'completePayment',
      'DeleteDiscount',
      'DeleteAddition',
      'SetBillingInfo',
      'SetBillingInfoFromShipping'
    ]),
    ...mapFilters(['formatMoney']),
    toggleBillingInfoForm (val) {
      this.BillingInfoFormOpen = val
    },
    goToPrev (checkout) {
      if (checkout.requires_shipping) {
        this.goToPrevStep()
      } else {
        this.goToStep(1)
      }
    },
    clearPaymentState () {
      this.SetActivePaymentMethod(null)
      this.DeleteDiscount({ key: 'id', value: null })
      this.DeleteAddition({ key: 'id', value: null })
      this.UpdatePaymentState({
        paymentId: null
      })
      this.UpdateCheckoutState({
        totals: {
          ...this.Checkout.totals,
          addition: null
        }
      })
    },
    ChoosePaymentProvider (provider) {
      if (provider) {
        this.setCheckoutStatus({
          PaymentOptions: {
            update: 'IDLE'
          }
        })
      }

      let method = provider.length > 0 ? JSON.parse(provider) : null

      this.DeleteDiscount({ key: 'id', value: `payment_method` })
      this.DeleteAddition({ key: 'id', value: `payment_method` })
      this.SetPaymentState({
        paymentId: method && method.type !== '' ? method.id : null
      })
    },

    async ValidatePaymentMethod (chosenMethod) {
      if (!chosenMethod.length) {
        this.setCheckoutStatus({
          PaymentOptions: {
            update: 'NOT_SELECTED'
          }
        })
        return false
      }

      var PaymentMethodIsValid = false

      try {
        let methodOBJ = JSON.parse(chosenMethod)

        if (methodOBJ.type === 'credit_card' && this.isPayPal()) {
          PaymentMethodIsValid = true
          this.continuePayPalPayment()
        } else {
          PaymentMethodIsValid = this.VerifyCreditCardFields(methodOBJ.type)
        }
        if (PaymentMethodIsValid && methodOBJ.type === 'credit_card' && !this.Payment.hash) {
          var paymentInformationForm = this.$refs[`PaymentMethod--${methodOBJ.type}`][0]

          if (paymentInformationForm) {
            var creditCardForm = paymentInformationForm.$refs.PaymentInformations.$children[0]

            if (creditCardForm && creditCardForm.generateHashAndUpdatePaymentInfo !== undefined) {
              await creditCardForm.generateHashAndUpdatePaymentInfo()
            }
          }

          if (!this.Payment.default_hash && !this.Payment.fallback_hash) {
            eventBus.$emit('creditCardHashFailed', '')
            return false
          }
        }
      } catch (error) {
        PaymentMethodIsValid = false
      }

      return PaymentMethodIsValid
    },
    VerifyCreditCardFields (paymentMethodType) {
      var isValid = false
      if (paymentMethodType !== 'credit_card') {
        return true
      }

      var paymentInformationForm = this.$refs[`PaymentMethod--${paymentMethodType}`][0].$refs.PaymentInformations

      if (this.GetPaymentProvider() === 'adyen') {
        paymentInformationForm.$children[0].$refs.PaymentInformations.validate((valid) => {
          isValid = valid
        })

        return isValid
      }

      paymentInformationForm.validate((valid) => {
        isValid = valid
      })

      return isValid
    },
    GetPaymentProvider () {
      return this.CheckoutConfig.paymentInformationConfig.credit_card_config.provider.name.toLowerCase()
    },
    isPayPal () {
      return this.CheckoutConfig.paymentInformationConfig.credit_card_config.provider.name === 'PayPal'
    },
    continuePayPalPayment () {
      eventBus.$emit('continuePayment', true)
    },
    async ContinuePayment (Checkout) {
      Checkout.activeStep = 3
      this.loading = true
      this.setCheckoutStatus({
        PaymentOptions: {
          update: 'LOADING'
        }
      })
      try {
        await this.validateCheckout()
      } catch (error) {
        this.loading = false
        return false
      }
      let $method = JSON.parse(this.chosenMethod)
      if ($method.type === 'two_cards_payment') {
        try {
          await this.$refs['PaymentMethod--two_cards_payment'][0].CompletePayment()
          return
        } catch (error) {
          this.loading = false
          return false
        }
      }
      let paymentFormValid = await this.ValidatePaymentMethod(this.chosenMethod)

      if (paymentFormValid) {
        this.setCheckoutStatus({
          PaymentOptions: {
            update: 'IDLE'
          }
        })
        if (this.IsNewBillingInfo) {
          await this.SetBillingInfo(
            {
              checkoutId: this.Checkout.id,
              billingInfo: this.Checkout.billing_info
            }).then(() => {
            this.loading = false
          })
          await this.completePayment({
            checkoutId: this.$route.params.checkout_id,
            payment: this.Payment
          }).then(() => {
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        } else {
          await this.SetBillingInfoFromShipping(
            {
              checkoutId: this.Checkout.id,
              shippingInfo: this.Checkout.shipping_info
            }).then(() => {
            this.loading = false
          })
          await this.completePayment({
            checkoutId: this.$route.params.checkout_id,
            payment: this.Payment
          }).then(() => {
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        }
      } else {
        this.loading = false
        return false
      }
    }
  },
  watch: {
    'ActivePaymentMethod': {
      handler (val) {
        if (val === null) {
          this.chosenMethod = ''
        }
      }
    }
  }
}
</script>
